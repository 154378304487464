import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Document, Page, pdfjs } from "react-pdf";
import styled from "styled-components";

import pdf from "../../Assets/Resume.pdf";
import Footer from "../Footer";
import Particle from "../Particle";
import { Cover } from "../ui/SpaceEffect";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Wrapper = styled.section`
  .resume-section {
    position: relative;
    padding-top: 110px;
    padding-bottom: 30px;
    color: white;
  }

  .resume {
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: center;
  }

  .resume-left {
    padding-right: 80px;
  }

  .resume-right {
    padding-left: 80px;
  }

  @media (max-width: 767px) and (max-aspect-ratio: 0.7) {
    .resume-left {
      padding-right: 15px;
      padding-left: 15px;
    }

    .resume-right {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .resume .resume-title {
    font-size: 2em;
    font-weight: 700;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .resume .resume-item {
    padding: 0 0 10px 25px;
    margin-top: -2px;
    border-left: 2px solid #8a49a8;
    position: relative;
  }

  .resume .resume-item .resume-title {
    line-height: 18px;
    font-size: 0.9em;
    background: #5234795d;
    padding: 8px 15px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .resume .resume-item ul {
    padding-left: 20px;
    text-align: justify;
  }

  .resume .resume-item ul li {
    padding-bottom: 10px;
    list-style: none;
  }

  .resume .resume-item:last-child {
    padding-bottom: 0;
  }

  .resume .resume-item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #fff;
    border: 2px solid #8a49a8;
  }
`;

function ResumeNew() {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <Wrapper>
      <Container fluid className="resume-section">
        <Particle />
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={pdf}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            Download CV
          </Button>
        </Row>

        <Row className="resume">
          <Document file={pdf} className="d-flex justify-content-center">
            <Page
              renderTextLayer={false}
              renderAnnotationLayer={false}
              customTextRenderer={false}
              pageNumber={1}
              scale={width > 786 ? 1.7 : 0.6}
            />
          </Document>
        </Row>

        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={pdf}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            Download CV
          </Button>
        </Row>
      </Container>
      <Footer />
    </Wrapper>
  );
}

export default ResumeNew;
