import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";
import Looks6Icon from "@mui/icons-material/Looks6";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Atropos from "atropos/react";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Container } from "react-bootstrap";
import Zoom from "react-medium-image-zoom";
import { Link } from "react-router-dom";
import styled from "styled-components";

import BackgroundButton from "../../Assets/Projects/BackgroundButton.svg";
import ChromaMix from "../../Assets/Projects/ChromaMix.png";
import CodeBuddy from "../../Assets/Projects/CodeBuddy.png";
import Flavorie from "../../Assets/Projects/Flavorie.png";
import InternshipForum from "../../Assets/Projects/InternshipForum.png";
import InternshipForumV1 from "../../Assets/Projects/InternshipForumV1.png";
import SeamLessLMS from "../../Assets/Projects/SeamLessLMS.png";
import View from "../../Assets/Projects/View.svg";
import Footer from "../Footer";
import Particle from "../Particle";
import Github from "./Github";
import Hover from "./Hover";
import ToolStack from "./Toolstack";

import "atropos/css";
import "react-medium-image-zoom/dist/styles.css";

const Wrapper = styled.section`
  overflow-y: auto;
  overflow-x: hidden;
  .project-section {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .project-title {
    margin-top: 40px;
    margin-bottom: 20px;
    color: white;
    font-size: 2rem;
    font-weight: 500;
    font-family: Georgia, "Times New Roman", Times, serif;
    padding-top: 10px;
  }
`;

const ButtonWrapper = styled.section`
  .container {
    padding: 0px 10px;
    max-width: 300px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    margin: 20px auto;
  }
  .atropos-banner {
    width: 100%;
    cursor: pointer;
  }
  .atropos-banner .atropos-inner {
    border-radius: 50px;
  }
  .atropos-banner img {
    position: absolute;
    left: -5%;
    top: -5%;
    width: 110%;
    height: 110%;
    object-fit: contain;
    display: block;
    z-index: 1;
    transform-style: preserve-3d;
    pointer-events: none;
  }
  .atropos-banner img.atropos-banner-spacer {
    position: relative;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
    visibility: hidden;
  }
  .atropos-banner .atropos-shadow {
    filter: blur(50px);
    opacity: 0.5;
  }
  .atropos-banner .atropos-highlight {
    z-index: 100;
  }
  .atropos-banner-text {
    position: absolute;
    color: #fff;
    font-weight: bold;
    left: 0%;
    top: 50%;
  }
`;
const SlideWrapper = styled.section`
  .container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card {
    padding-bottom: 20px;
    width: 10vw;
    height: 40vh;
    border-radius: 0.75rem;
    cursor: pointer;
    overflow: hidden;
    border-radius: 2rem;
    margin: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
    transition: width 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275),
      transform 0.6s cubic-bezier(0.28, -0.03, 0, 0.99);
    text-align: center;
    box-shadow: 0px 10px 30px -5px #2af4a6;
    padding-bottom: 20px;
  }
  .card:hover {
    transform: scale(1.05);
    overflow: hidden;
    box-shadow: 2px 4px 6px 4px #2af4a6;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 30vh;
    opacity: 0;
    display: none;
    transition: width 0.5s ease-in-out;
  }
  .icon {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .project-heading {
    color: white;
    font-size: 1.7rem;
    opacity: 0;
    font-weight: 500;
    font-family: Georgia, "Times New Roman", Times, serif;
    padding-top: 10px;
  }
  .project-description {
    padding: 10px;
    color: white;
    font-size: 0.8rem;
    opacity: 0;
    font-weight: 400;
    font-family: Georgia, "Times New Roman", Times, serif;
    padding-top: 10px;
  }
  input {
    display: none;
  }

  input:checked + label {
    width: 34vw;
  }

  input:checked + label .project-description,
  input:checked + label .project-heading,
  input:checked + label img {
    display: inline;
    opacity: 1;
    width: 100%;
    transform: translateY(0);
  }

  input:checked + label .icon {
    opacity: 0;
  }

  @media only screen and (width < 800px),
    (max-aspect-ratio: 0.8),
    (orientation: portrait) {
    .card {
      width: 75vw;
      height: 10vh;
      transition: height 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275),
        transform 0.5s ease-in-out;
    }
    input:checked + label {
      width: 75vw;
      height: 43vh;
    }
    .container {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: start;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .project-description {
      font-size: 1.2rem;
    }
  }
`;
function Projects() {
  const projectDescription = [
    {
      title: "Flavorie",
      description:
        "Cook with others live and effortlessly, and share your moments with everyone.",
      image: Flavorie,
      link: "/project/Flavorie",
    },
    {
      title: "CodeBuddy",
      description: "A Chrome extension for live teamwork on LeetCode problems.",
      image: CodeBuddy,
      link: "/project/CodeBuddy",
    },
    {
      title: "ChromaMix",
      description:
        "A simple website that generates a random color palette with tint and shades.",
      image: ChromaMix,
      link: "/project/ChromaMix",
    },
    {
      title: "Internship Forum",
      description:
        "A web platform designed for everyone to share, explore, and review internship.",
      image: InternshipForum,
      link: "/project/InternshipForum",
    },
    {
      title: "Internship Forum V1",
      description: "Previous version: HTML, CSS, JavaScript for frontend.",
      image: InternshipForumV1,
      link: "/project/InternshipForumV1",
    },
    {
      title: "SeamLess LMS",
      description:
        "A role-based Learning Management System website for online education.",
      image: SeamLessLMS,
      link: "/project/SeamLessLMS",
    },
  ];
  const [selectedSlide, setSelectedSlide] = React.useState(0);
  const [error, setError] = React.useState(false);
  const handleOnClick = (event) => {
    if (selectedSlide === 0) {
      event.preventDefault();
      setError(true);
    }
    setTimeout(() => {
      setError(false);
    }, 2000);
  };
  const handleSlideChange = (slideNumber) => {
    setSelectedSlide(slideNumber);
  };

  return (
    <Wrapper>
      <Particle />
      <Container fluid className="project-section">
        <h1 className="project-title">
          {selectedSlide !== 0
            ? "My Project: "
            : !error
            ? "My Recent"
            : "Please Click"}{" "}
          <strong className="purple">
            {selectedSlide === 0 &&
              (!error ? "Projects" : "One Project to View")}
            {selectedSlide !== 0 && projectDescription[selectedSlide - 1].title}
          </strong>
        </h1>
        <Link
          onClick={handleOnClick}
          to={
            selectedSlide !== 0
              ? projectDescription[selectedSlide - 1].link
              : "."
          }
        >
          <Hover
            element={
              <ButtonWrapper>
                <div className="container">
                  <Atropos className="atropos-banner" highlight={true}>
                    <img
                      className="atropos-banner-spacer"
                      src="https://raw.githubusercontent.com/nolimits4web/atropos/master/playground/react/i/atropos-bg.svg"
                      alt=""
                    />
                    <img
                      data-atropos-offset="-4.5"
                      data-atropos-opacity="1;4"
                      src={BackgroundButton}
                      alt=""
                    />
                    <img
                      className="atropos-banner-text"
                      data-atropos-offset="5"
                      data-atropos-opacity="0;4"
                      src={View}
                      alt=""
                    />
                  </Atropos>
                </div>
              </ButtonWrapper>
            }
          />
        </Link>
        <SlideWrapper>
          <div className="container">
            {projectDescription.map((image, index) => (
              <section key={index}>
                <input
                  type="radio"
                  name="slide"
                  id={`c${index + 1}`}
                  checked={selectedSlide === index + 1}
                  onChange={() => handleSlideChange(index + 1)}
                />
                <label htmlFor={`c${index + 1}`} className="card">
                  {index === 0 && (
                    <LooksOneIcon
                      style={{ color: "#ff6484" }}
                      className="icon"
                    />
                  )}
                  {index === 1 && (
                    <LooksTwoIcon
                      style={{ color: "#4bc0c0" }}
                      className="icon"
                    />
                  )}
                  {index === 2 && (
                    <Looks3Icon style={{ color: "#36a2eb" }} className="icon" />
                  )}
                  {index === 3 && (
                    <Looks4Icon style={{ color: "#ffcd56" }} className="icon" />
                  )}
                  {index === 4 && (
                    <Looks5Icon style={{ color: "#cb3d6e" }} className="icon" />
                  )}
                  {index === 5 && (
                    <Looks6Icon style={{ color: "#5dd39e" }} className="icon" />
                  )}
                  <Zoom className="zoom-image">
                    <img alt="hmm" src={image.image} />
                  </Zoom>
                  <h4 className="project-heading">{image.title}</h4>
                  <p className="project-description">{image.description}</p>
                </label>
              </section>
            ))}
          </div>
        </SlideWrapper>
        <h1 className="project-title">
          My Favorite <strong className="purple">Tools</strong>
        </h1>
        <ToolStack />
        <h1 className="project-title">
          Days I <strong className="purple">Code</strong>
        </h1>
        <Github />
      </Container>
      <Footer />
    </Wrapper>
  );
}
export default Projects;
