import React from "react";
import styled from "styled-components";

import AppleIcon from "../../Assets/ToolStackLogo/Apple.svg";
import PostManIcon from "../../Assets/ToolStackLogo/PostMan.svg";
import SlackIcon from "../../Assets/ToolStackLogo/Slack.svg";
import VSCodeIcon from "../../Assets/ToolStackLogo/VSCode.svg";

const CardWrapper = styled.section`
.image-icon {
  width:40%;
}


#wrapper-outside {
  position: relative;
  height: 350px;
  width: 240px;
}

#wrapper {
  position: absolute;
  left:120px;
  top: 150px;
  perspective: 1500px;
}

.box-area {
  position: relative;
  transform-style: preserve-3d;
  animation-name: rotate;
  animation-duration: 25s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  0% {
    transform: rotate3d(0, 0, 0, 0);
  }
  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}

.box {
  position: fixed;
  width: 240px;
  height: 480px;
}

.image-icon{
  width: 80%;
}

#box-front {
  transform: translateX(-120px) translateY(-121px) translateZ(120px);
  background: rgba(255, 16, 108, 0);
}

#box-back {
  transform: translateX(-120px) translateY(-120px) translateZ(-120px);
  background: rgba(1, 251, 234, 0);
}

#box-right {
  transform: translateY(-120px) rotateY(90deg);
  background: rgba(4, 255, 117, 0);
}

#box-left {
  transform: translateY(-120px) translateX(-240px) rotateY(90deg);
  background: rgba(217, 255, 0, 0);
}

#box-top {
  transform: translateX(-120px) translateY(-240px) rotateX(90deg);
  background: rgba(0, 4, 246, 0);
}

#box-bottom {
  transform: translateX(-120px) rotateX(90deg);
  background: rgba(255, 196, 0, 0.237);
  border-radius: 2px;
  box-shadow: 0 20px 30px rgb(234, 212, 126);
  filter: blur(100px);
  border: 1px solid #d5c30000;
}

.box-area {
  transform: rotateX(90deg) rotateY(90deg);
}
.button {
  text-decoration: none;
  color: #c5c3bb;
  background: rgba(244, 191, 1, 0.272);
  padding: 128px 78px 100px;
  border: 3px solid #ffffff;
  font-weight: normal;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
}

.glow-button:hover {
  background-color: #e5e2d7;
  color: rgb(234, 219, 162);
  box-shadow: 0 20px 30px rgba(252, 243, 207, 0.6);
}
`

function ToolStack() {
  return (
    <CardWrapper>
      <div id = "wrapper-outside">
        <div id="wrapper">
          <div className="box-area">
            <div id="box-front" className="box">
              <div className="button glow-button">
                <div id="box-front-content">
                  <img src={AppleIcon} className="image-icon" alt="" />
                </div>
              </div>
            </div>
            <div id="box-right" className="box">
              <div className="button glow-button">
                <div id="box-right-content">
                  <img src={PostManIcon} className="image-icon" alt="" />
                </div>
              </div>
            </div>
            <div id="box-back" className="box">
              <div className="button glow-button">
                <div id="box-back-content">
                  <img src={VSCodeIcon} className="image-icon" alt="" />
                </div>
              </div>
            </div>
            <div id="box-left" className="box">
              <div className="button glow-button">
                <div id="box-left-content">
                  <img src={SlackIcon} className="image-icon" alt="" />
                </div>
              </div>
            </div>
            <div id="box-bottom" className="box">
              <img src={AppleIcon} className="image-icon" alt="" />
            </div>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
}

export default ToolStack;
