import { Tooltip as MuiTooltip } from '@mui/material';
import React from "react";
import GitHubCalendar from "react-github-calendar";

function Github() {
  const selectLastHalfYear = contributions => {
    function isInRecentMonths(date, recentMonths) {
      if (isNaN(recentMonths) || recentMonths <= 0) {
        throw new Error('Invalid value for recentMonths. It must be a positive number.');
      }
    
      // Get the current date
      const currentDate = new Date();
    
      // Calculate the date based on the number of recent months
      const recentMonthsAgo = new Date();
      recentMonthsAgo.setMonth(currentDate.getMonth() - recentMonths);
    
      // Check if the given date is between recentMonthsAgo and the current date
      return date >= recentMonthsAgo && date <= currentDate;
    }
    return contributions.filter(activity => {
      const date = new Date(activity.date);
      return isInRecentMonths(date, 3);
    });
  };
  const explicitTheme= {
    light: ['#f0f0f0', '#c4edde', '#7ac7c4', '#f73859', '#384259'],
    dark: ['#00695c', '#009688', '#00bcd4', '#18ffff', '#1de9b6'],
  };
  return (

      <GitHubCalendar
        username="hungngodev"
        blockSize={25}
        blockMargin={20}
        transformData={selectLastHalfYear}
        color="#1de9b6"  
        showWeekdayLabels={true}
        renderBlock={(block, activity) => (
          <MuiTooltip
            title={`${activity.count} activities on ${activity.date}`}
          >
            {block}
          </MuiTooltip>
        )}
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          color: "#fff",
          position: "relative",
          zIndex:100,
        }}
        colorScheme="dark"
        theme={explicitTheme}
      />
  );
}

export default Github;
