import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Cover } from "../ui/SpaceEffect";

const Wrapper = styled.section`
  .home-header {
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
    text-align: center;
    align-items: center;
  }
  .button-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
    text-align: center;
    align-items: center;
  }

  .heading-welcome {
    font-size: 1.5rem;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: white;
  }
  .heading-name {
    font-size: 2.5em;
    font-weight: 400;
    color: white;
  }
  .heading-status {
    font-size: 1rem;
    font-weight: 400;
    color: white;
    font-family: "Roboto Mono", monospace;
  }
  .main-name {
    color: #1de9b6;
  }
`;
const ButtonWrapper = styled.section`
  button {
    text-decoration: none;
    position: relative;
    border: none;
    font-size: 1.1rem;
    font-family: Georgia, "Times New Roman", Times, serif;
    cursor: pointer;
    color: #fff;
    width: 9rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 300%;
    border-radius: 30px;
    z-index: 10;
  }

  button:hover {
    animation: ani 8s linear infinite;
    border: none;
  }

  @keyframes ani {
    0% {
      background-position: 0%;
    }

    100% {
      background-position: 400%;
    }
  }

  button:before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 35px;
    transition: 1s;
  }

  button:hover::before {
    filter: blur(20px);
  }

  button:active {
    background: linear-gradient(32deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  }
`;
function Hero({ onClickF }) {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col xs={12} className="home-header">
            <h1 className="heading-name">
              I'm
              <strong className="main-name"> Hung Ngo</strong>
            </h1>
            <h1 className="text-3xl font-semibold w-screen text-center  relative z-20 py-6 bg-clip-text text-transparent">
              <Cover> 🌐 Status: Open to any opportunities</Cover>
            </h1>
          </Col>
          <Col xs={12} className="button-container">
            <ButtonWrapper>
              <button
                onClick={() => {
                  onClickF(4);
                }}
              >
                Contact Me
              </button>
            </ButtonWrapper>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Hero;
