import React, { useRef } from "react";
import styled from "styled-components";
const Wrapper = styled.section`
  .outside-wrapper {
    background-color: #000;
    display: flex;
    justify-content: center;
    overflow: hidden;
    font-family: "Noto Sans", sans-serif;
  }
  .card-track {
    height: auto;
    width: 480px;
    display: flex;
    align-items: center;
    position: relative;
  }

  .card-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 2.1;
    position: relative;
    z-index: 10;
    background-color: black;
    border-radius: 2rem;
    overflow: hidden;
    cursor: pointer;
  }

  .card-image {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 4;
  }

  .card-image > img {
    width: calc(480px * 0.3);
  }

  .card-gradient {
    height: 100%;
    width: 100%;
    position: absolute;
    background: radial-gradient(
      rgb(30 41 59) 40%,
      rgb(41 121 255) 50%,
      rgb(56 182 255),
      rgb(42 252 152)
    );
    mix-blend-mode: darken;
    pointer-events: none;
    z-index: 3;
  }

  .card-letters {
    --x: 0px;
    --y: 0px;
    position: absolute;
    opacity: 1;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    color: white;
    font-size: 0.8rem;
    font-weight: 500;
    word-wrap: break-word;
    opacity: 0;
    transition: opacity 400ms;
    mask-image: radial-gradient(
      calc(300px * 0.8) circle at var(--x) var(--y),
      rgb(255 255 255) 20%,
      rgb(255 255 255 / 25%),
      transparent
    );
    scale: 1.03;
  }
  .card:hover .card-letters {
    opacity: 1;
  }
`;
const Hover = ({element}) => {
  const chars =
    "01010101010101010101010";

  const randomChar = () =>
      chars[Math.floor(Math.random() * (chars.length - 1))],
    randomString = (length) =>
      Array.from(Array(length)).map(randomChar).join("");
  const cardRef = useRef(null);
  const letterRef = useRef(null);

  const handleOnMove = (e) => {
    const rect = cardRef.current.getBoundingClientRect(),
      x = e.clientX - rect.left,
      y = e.clientY - rect.top;

    letterRef.current.style.setProperty("--x", `${x}px`);
    letterRef.current.style.setProperty("--y", `${y}px`);
    letterRef.current.innerText = randomString(1500);
  };
  return (
    <Wrapper>
      <div className="outside-wrapper">
        <div className="card-track">
          <div className="card-wrapper">
            <div
              className="card"
              ref={cardRef}
              onMouseMove={handleOnMove}
              onTouchMove={(e) => handleOnMove(e.touches[0])}
            >
              <div className="card-image">
                {element}
              </div>
              <div className="card-gradient"></div>
              <div className="card-letters" ref={letterRef}></div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Hover;
