import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { AiOutlineFundProjectionScreen, AiOutlineHome } from "react-icons/ai";
import { BiSolidContact } from "react-icons/bi";
import { CgFileDocument } from "react-icons/cg";
import { FaScroll } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.section`
  .sticky {
    background-color: rgba(9, 5, 29, 0.171);
    transition: all 0.3s ease-out 0s;
    box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171);
    backdrop-filter: blur(15px);
  }

  .navbar {
    position: fixed;
    transition: all 0.3s ease-out 0s;
    padding: 0.2rem 2rem 0.4rem 2rem;
    font-size: 0.8rem;
  }

  .navbar-toggler {
    position: relative;
    background-color: transparent;
    border-color: transparent;
  }

  .navbar-toggler span {
    display: block;
    background-color: #be50f4;
    height: 4px;
    width: 27px;
    margin-top: 5px;
    margin-bottom: 5px;
    transform: rotate(0deg);
    left: 0;
    opacity: 1;
  }

  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: 0;
  }

  .navbar-toggler span:nth-child(1),
  .navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out;
    transition: transform 0.35s ease-in-out;
  }

  .navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute;
    left: 12px;
    top: 10px;
    transform: rotate(135deg);
    opacity: 0.9;
  }

  .navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px;
    visibility: hidden;
    background-color: transparent;
  }

  .navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 10px;
    transform: rotate(-135deg);
    opacity: 0.9;
  }

  @media (max-width: 767px), (max-aspect-ratio: 0.8), (orientation: portrait) {
    .navbar {
      padding: 1rem 2rem;
      font-size: 1.4rem;
      background-color: #181a27;
    }
    .navbar-nav .nav-item a::after {
      display: none;
    }
  }
  .navbar-brand {
    color: rgb(250, 250, 250);
  }

  .logo {
    height: 1.4em;
    width: 2.5em;
  }

  .navbar-nav .nav-link {
    text-align: center;
    color: white;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .nav-link {
    padding: 0.8rem 1rem;
  }

  .navbar-nav .nav-item {
    position: relative;
    margin-left: 20px;
  }

  .navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
  }

  .navbar-nav .nav-item a::after {
    content: "";
    position: relative;
    display: block;
    height: 5px;
    width: 0;
    border-radius: 16px;
    background: #c95bf5;
    bottom: 1px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
  }

  .navbar-nav .nav-item a:hover::after {
    width: 100%;
  }

  .fork-btn {
    font-size: 1.1em;
    padding-top: 10px;
  }

  .fork-btn-inner {
    line-height: 1.4em;
    background-color: #934cce5e;
    padding: 0.25rem 1.1rem;
    vertical-align: middle;
    text-align: center;
  }

  .fork-btn-inner:hover {
    transform: translateY(-2px);
    background-color: #a24dd386;
    border-color: #a24dd386;
  }
  .fork-btn-inner::after {
    display: none;
  }
`;

function NavBar({ home }) {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const showHome = home ? true : false;
  let checked = home?.checked;
  const setChecked = home?.setChecked;
  const MoveHandle = home?.MoveHandle;

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Wrapper>
      <Navbar
        expanded={expand}
        fixed="top"
        expand="md"
        className={navColour ? "sticky" : "navbar"}
      >
        <Container>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => {
              updateExpanded(expand ? false : "expanded");
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto" defaultActiveKey="#home">
              {showHome && (
                <>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        setChecked(!checked);
                        updateExpanded(false);
                      }}
                    >
                      <FaScroll style={{ marginBottom: "2px" }} /> Scroll Bar
                      <span style={{ color: checked ? "#18ffff" : "#ff4081" }}>
                        {checked ? " On" : " Off"}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        updateExpanded(false);
                        MoveHandle(4);
                      }}
                    >
                      <BiSolidContact style={{ marginBottom: "2px" }} /> Contact
                    </Nav.Link>
                  </Nav.Item>
                </>
              )}
              <Nav.Item>
                {showHome ? (
                  <Nav.Link
                    onClick={() => {
                      updateExpanded(false);
                      MoveHandle(0);
                    }}
                  >
                    <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
                  </Nav.Link>
                ) : (
                  <Nav.Link
                    as={Link}
                    to="/"
                    onClick={() => {
                      updateExpanded(false);
                      MoveHandle(0);
                    }}
                  >
                    <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
                  </Nav.Link>
                )}
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/project"
                  onClick={() => updateExpanded(false)}
                >
                  <AiOutlineFundProjectionScreen
                    style={{ marginBottom: "2px" }}
                  />{" "}
                  Projects
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/resume"
                  onClick={() => updateExpanded(false)}
                >
                  <CgFileDocument style={{ marginBottom: "2px" }} /> Resume
                </Nav.Link>
              </Nav.Item>
              {/* 
              <Nav.Item className="fork-btn">
                <Button
                  href="https://github.com/hungngodev"
                  target="_blank"
                  className="fork-btn-inner"
                >
                  <CgGitFork style={{ fontSize: "1.2em" }} />{" "}
                  <AiFillStar style={{ fontSize: "1.1em" }} />
                </Button>
              </Nav.Item> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Wrapper>
  );
}

export default NavBar;
