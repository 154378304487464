import React from "react";
import { Link } from "react-router-dom";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import description from "./ProjectDescription";

const Wrapper = styled.section`
  overflow: hidden;
  .item {
    width: 17vw;
    height: 18vh;
    list-style-type: none;
    position: absolute;
    transform: translateY(-50%);
    z-index: 1;
    left: 81.5%;
    background-position: start;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 20px;
    box-shadow: 0 20px 30px rgba(255, 255, 255, 0.3) inset;
    transition: transform 0.2s, left 0.4s, top 0.2s, width 0.2s, height 0.45s;

    &:nth-child(1),
    &:nth-child(2) {
      left: 0;
      top: 10%;
      width: 80%;
      height: 80vh;
      transform: none;
      border-radius: 0;
      box-shadow: none;
      opacity: 1;
    }
    &:nth-child(n + 3) {
      border: 2px solid white;
      background-size: cover;
    }
    &:nth-child(3) {
      top: 47%;
    }
    &:nth-child(4) {
      top: calc(47% + 22vh);
    }
    &:nth-child(5) {
      top: calc(47% + 44vh);
    }
    &:nth-child(n + 6) {
      opacity: 0;
    }
  }

  .content {
    width: 20vw;
    height: 20vh;
    position: absolute;
    text-align: center;
    top: 1%;
    left: 100%;
    border-radius: 20px;
    color: white;
    text-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
    opacity: 0;
    display: none;

    & .title {
      font-family: "Playfair Display", serif;
      font-weight: 700;
      font-size: 1.3rem;
      text-transform: uppercase;
    }

    & .description {
      line-height: 1.2;
      font-size: 0.8rem;
    }

    & button {
      width: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      color: white;
      border: 2px solid white;
      border-radius: 10px;
      padding: 0.15rem;
      cursor: pointer;
    }
  }

  .item:nth-of-type(2) .content {
    display: block;
    animation: show 0.55s ease-in-out 0.3s forwards;
  }

  @keyframes show {
    0% {
      filter: blur(5px);
      transform: translateY(calc(-50% + 75px));
    }
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }
  .nav {
    position: absolute;
    bottom: 0;
    top: 90%;
    left: 50%;
    display: flex;
    justify-content: center;
    transform: translateX(-50%);
    z-index: 10;
    user-select: none;
    & .btn {
      background-color: rgba(255, 255, 255, 0.8);
      color: rgba(0, 0, 0, 0.7);
      border: 2px solid rgba(0, 0, 0, 0.6);
      padding: 0.75rem;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }
  @media only screen and (width > 600px) and (width < 800px) {
    .content {
      & .title {
        font-size: 1rem;
      }
      & .description {
        font-size: 0.7rem;
      }
      & button {
        font-size: 0.7rem;
      }
    }
    .item {
      &:nth-child(3) {
        top: 40%;
      }
      &:nth-child(4) {
        top: calc(40% + 25vh);
      }
      &:nth-child(5) {
        top: calc(40% + 50vh);
      }
      &:nth-child(n + 6) {
        opacity: 0;
      }
    }
  }

  @media only screen and (width < 600px),
    (max-aspect-ratio: 0.8),
    (orientation: portrait) {
    .content {
      width: 100%;
      top: 160%;
      left: 0;
      & .title {
        font-size: 2rem;
      }
      & .description {
        font-size: 1.5rem;
      }
      & button {
        font-size: 1rem;
        width: 100%;
        border-radius: 0;
      }
    }

    .item {
      width: 30vw;
      height: 15vh;
      top: 65%;
      transition: transform 0.2s, left 0.4s, top 0.2s, width 0.2s, height 0.45s;

      &:nth-child(1),
      &:nth-child(2) {
        left: 0;
        top: 10%;
        width: 100%;
        height: 40vh;
        transform: none;
        border-radius: 0;
        box-shadow: none;
        border: none;
        opacity: 1;
      }
      &:nth-child(n + 3) {
        top: 65%;
      }
      &:nth-child(3) {
        left: 0%;
      }
      &:nth-child(4) {
        left: calc(0% + 35vw);
      }
      &:nth-child(5) {
        left: calc(0% + 70vw);
      }
      &:nth-child(n + 6) {
        opacity: 0;
      }
    }
  }
`;
function ProjectCard(props) {
  const { projectName } = useParams();
  const projectDescription = description[projectName].description;
  const link = description[projectName].link;
  const Prevactivate = (e) => {
    const slider = document.querySelector(".slider");
    const items = document.querySelectorAll(".item");
    slider.prepend(items[items.length - 1]);
  };

  const Nextactivate = (e) => {
    const slider = document.querySelector(".slider");
    const items = document.querySelectorAll(".item");
    slider.append(items[0]);
  };

  const ListItem = ({ title, description, src, link }) => (
    <li className="item" style={{ backgroundImage: `url('${src}')` }}>
      <Container fluid className="content">
        <h2 className="title my-2">{title}</h2>
        <p className="description text- my-2">{description}</p>
        <Link to={link}>
          <button>Demo</button>
        </Link>
      </Container>
    </li>
  );
  return (
    <Wrapper>
      <ul className="slider">
        {projectDescription.map((item, index) => (
          <ListItem key={index} {...item} link={link} />
        ))}
      </ul>
      <nav className="nav">
        <Container>
          <Row>
            <Col xs={6}>
              <button
                onClick={() => Prevactivate()}
                name="prev"
                className=" btn prev"
              >
                <GrFormPrevious />
              </button>
            </Col>
            <Col xs={6}>
              <button
                onClick={() => Nextactivate()}
                name="next"
                className=" btn next"
              >
                <GrFormNext />
              </button>
            </Col>
          </Row>
        </Container>
      </nav>
    </Wrapper>
  );
}

export default ProjectCard;
