import React from "react";
import Typewriter from "typewriter-effect";
import styled from "styled-components";

const Wrapper = styled.section`
  text-align: center;
  font-size:1rem;
  position:relative;
  z-index: 20;
  width: 100%;
  margin-top:100px;
  .Typewriter__wrapper {
  font-size: 2.2em ;
  color: #1de9b6;
  font-weight: 600 ;
}
.Typewriter__cursor {
  font-size: 2.4em ;
  color: #00e5ff ;
}

`

function Type() {
  return (
    <Wrapper>
      <Typewriter
        options={{
          strings: [
            "CS & Math Student",
            "Software Engineer",
            "MERN Stack Dev",
            "Nature Lover",
          ],
          delay:2,
          autoStart: true,
          loop: true,
          deleteSpeed: 8,
          pause:800,

        }}
      />
    </Wrapper>
  );
}

export default Type;
