import React from "react";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import styled from "styled-components";

const Wrapper = styled.section`
  .about-section {
    position: relative;
    color: white;
    padding: 20px;
    width: 100%;
    padding: 0 70px;
  }
  .heading,
  .quote,
  .blockquote-footer {
    margin-right: 50px;
  }
  .quote-card-view {
    border: none;
    color: white;
    background-color: transparent;
  }
  .blockquote {
    line-height: 1.6rem;
    font-size: 1rem;
  }
  .about-activity {
    list-style: none;
    text-align: left;
    padding-left: 1px;
    font-size: 1rem;
    line-height: 2rem;
  }
`;
function AboutCard({ alignCustom }) {
  return (
    <Wrapper>
      <Row className="about-section">
        <Col
          xs={12}
          style={{
            justifyContent: "start",
            paddingTop: "30px",
            paddingBottom: "50px",
          }}
        >
          <h1
            style={{
              fontSize: "2.1em",
              textAlign: alignCustom ? "left" : "center",
            }}
          >
            About <strong className="purple">Me</strong>
          </h1>
          <Card className="quote-card-view">
            <Card.Body>
              <blockquote className="blockquote mb-0">
                <p style={{ textAlign: "start" }}>
                  Hi everyone, I am <span className="purple">Hung Ngo </span>
                  from <span className="purple"> Amherst, Massachusetts</span>
                  <br />
                  Currently pursuing a degree in Computer Science and
                  Mathematics at UMass Amherst.
                  <br />
                  I am actively seeking internships and open to any
                  opportunities!!!.
                  <br />
                  Apart from coding, I enjoy engaging in various activities:
                </p>
                <ul>
                  <li className="about-activity">
                    <ImPointRight /> Crafting things in MakerSpace
                  </li>
                  <li className="about-activity">
                    <ImPointRight /> Listening to lofi music
                  </li>
                  <li className="about-activity">
                    <ImPointRight /> Building Mechanical Keyboards
                  </li>
                </ul>

                <p
                  className="quote"
                  style={{
                    color: "rgb(155 126 172)",
                    textAlign: alignCustom ? "left" : "center",
                  }}
                >
                  "Hapiness is created from hardwork!"{" "}
                </p>
                <footer
                  className="blockquote-footer"
                  style={{
                    textAlign: alignCustom ? "left" : "center",
                    marginTop: "10px",
                  }}
                >
                  Hung
                </footer>
              </blockquote>
            </Card.Body>
          </Card>
        </Col>
      </Row>{" "}
    </Wrapper>
  );
}

export default AboutCard;
