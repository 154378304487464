import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillGithub, AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import styled from "styled-components";

const Wrapper = styled.section`
  .footer {
    background: transparent;
    bottom: 0;
    margin: 0;
    font-family: Georgia, "Times New Roman", Times, serif;
  }
  .footer-copywright {
    text-align: center;
  }

  .footer-body {
    z-index: 1;
    justify-content: center;
    display: flex;
  }

  .footer h3 {
    font-size: 1em;
    color: white;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .footer-icons {
    margin: 0 1rem;
    padding: 0;
  }
`;
function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Wrapper>
      <Container fluid className="footer">
        <Row>
          <Col xs={12} md={4} className="footer-copywright">
            <h3>Copyright © {year} HN</h3>
          </Col>
          <Col xs={12} md={4} className="footer-copywright">
            <h3>Designed and Developed by Hung Ngo</h3>
          </Col>
          <Col xs={12} md={4} className="footer-body">
            <a
              href="https://github.com/hungngodev"
              style={{ color: "white" }}
              target="_blank"
              className="footer-icons"
              rel="noopener noreferrer"
            >
              <AiFillGithub />
            </a>
            <a
              href="https://www.linkedin.com/in/hungngo1607/"
              style={{ color: "white" }}
              target="_blank"
              className="footer-icons"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn />
            </a>
            <a
              href="https://www.instagram.com/minhhung.ngo.313371/"
              style={{ color: "white" }}
              target="_blank"
              className="footer-icons"
              rel="noopener noreferrer"
            >
              <AiFillInstagram />
            </a>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Footer;
