const calculatingAR = (aspectRatio) => {
    const result =
        (aspectRatio < 0.56) ? "9/19.5" :
            (aspectRatio < 0.65) ? "9/16" :
                (aspectRatio < 0.9) ? "3/4" :
                    (aspectRatio < 1.1) ? "1" :
                        (aspectRatio < 1.3) ? "7/6" :
                            (aspectRatio < 1.5) ? "4/3" :
                                (aspectRatio < 1.7) ? "14/9" :
                                    (aspectRatio < 1.9) ? "16/9" :
                                        (aspectRatio < 2.1) ? "2" :
                                            (aspectRatio < 2.3) ? "13/6" :
                                                (aspectRatio < 2.5) ? "21/9" :
                                                    "16/9"
    return result
}
const objectStage = {
    "9/19.5": {
        0: 0,
        1: 0.48,
        2: 1.28,
        3: 2,
        4: 3.05,
    },
    "9/16": {
        0: 0,
        1: 0.515,
        2: 1.28,
        3: 2,
        4: 3.05,
    },
    "3/4": {
        0: 0,
        1: 0.53,
        2: 1.29,
        3: 2,
        4: 3.05,
    },
    "1": {
        0: 0,
        1: 0.38,
        2: 1.3,
        3: 2.147,
        4: 3.2,
    },
    "7/6": {
        0: 0,
        1: 0.44,
        2: 1.3,
        3: 2.147,
        4: 3.4,
    },
    "4/3": {
        0: 0,
        1: 0.48,
        2: 1.345,
        3: 2.147,
        4: 3.4,
    },
    "14/9": {
        0: 0,
        1: 0.545,
        2: 1.36,
        3: 2.147,
        4: 3.4,
    },
    "16/9": {
        0: 0,
        1: 0.57,
        2: 1.3,
        3: 2.147,
        4: 3.05,
    },
    "2": {
        0: 0,
        1: 0.6,
        2: 1.3,
        3: 2.147,
        4: 3.05,
    },
    "13/6": {
        0: 0,
        1: 0.64,
        2: 1.3,
        3: 2.147,
        4: 3.05,
    },
    "21/9":
    {
        0: 0,
        1: 0.68,
        2: 1.3,
        3: 2.15,
        4: 3.05,
    },
}
const AspectRatio = {
    "21/9": {
        pages: 4.7,
        layer1: {
            offset: 0,
            speed: 1.4,
        },
        layer2: {
            offset: 0.2,
            speed: 2,
        },
        layer3: {
            offset: 0,
            speed: -2.3,
        },
        layer4: {
            offset: 0.7,
            speed: -0.99,
        },
        layer5: {
            offset: 1.12,
            speed: 0.5,
        },
        layer6: {
            offset: 1.8,
            speed: 0,
        },
        layer7: {
            offset: 1.4,
            speed: 0.1,
            height: "320%",
            width: "70%",
        },
        layer8: {
            offset: -1,
            speed: -0.55,
        },
        layer9: {
            offset: 1.35,
            speed: 0.3,
        },
        layer10: {
            offset: 2.2,
            speed: 0.4,
        },
        layer11: {
            offset: 3,
            speed: 0.2,
        },
        
    },
    "13/6": {
        pages: 4.6,
        layer1: {
            offset: 0,
            speed: 1.4,
        },
        layer2: {
            offset: 0.2,
            speed: 2,
        },
        layer3: {
            offset: 0,
            speed: -2.7,
        },
        layer4: {
            offset: 0.7,
            speed: -0.99,
        },
        layer5: {
            offset: 1.12,
            speed: 0.5,
        },
        layer6: {
            offset: 1.68,
            speed: 0,
        },
        layer7: {
            offset: 1.1,
            speed: 0.53,
            height: "320%",
            width: "70%",
        },
        layer8: {
            offset: -1,
            speed: -0.45,
        },
        layer9: {
            offset: 1.35,
            speed: 0.3,
        },
        layer10: {
            offset: 2.2,
            speed: 0.4,
        },
        layer11: {
            offset: 3,
            speed: 0.2,
        },
        
    },
    "2": {
        pages: 4.4,
        layer1: {
            offset: 0,
            speed: 1.4,
        },
        layer2: {
            offset: 0.2,
            speed: 2,
        },
        layer3: {
            offset: 0,
            speed: -2.6,
        },
        layer4: {
            offset: 0.7,
            speed: -0.99,
        },
        layer5: {
            offset: 1.12,
            speed: 0.5,
        },
        layer6: {
            offset: 1.6,
            speed: 0,
        },
        layer7: {
            offset: 1.1,
            speed: 0.53,
            height: "320%",
            width: "70%",
        },
        layer8: {
            offset: -1,
            speed: -0.45,
        },
        layer9: {
            offset: 1.35,
            speed: 0.3,
        },
        layer10: {
            offset: 2.2,
            speed: 0.4,
        },
        layer11: {
            offset: 3,
            speed: 0.2,
        },
        
    },
    "16/9": {
        pages: 4.2,
        layer1: {
            offset: 0,
            speed: 1.4,
        },
        layer2: {
            offset: 0.2,
            speed: 2,
        },
        layer3: {
            offset: 0,
            speed: -2.7,
        },
        layer4: {
            offset: 0.7,
            speed: -0.99,
        },
        layer5: {
            offset: 1.12,
            speed: 0.5,
        },
        layer6: {
            offset: 1.6,
            speed: 0,
        },
        layer7: {
            offset: 1.1,
            speed: 0.53,
            height: "320%",
            width: "70%",
        },
        layer8: {
            offset: -1,
            speed: -0.45,
        },
        layer9: {
            offset: 1.35,
            speed: 0.3,
        },
        layer10: {
            offset: 2.2,
            speed: 0.4,
        },
        layer11: {
            offset: 3,
            speed: 0.2,
        },
        
    },
    "14/9": {
        pages: 4.6,
        layer1: {
            offset: 0,
            speed: 1.4,
        },
        layer2: {
            offset: 0.2,
            speed: 2,
        },
        layer3: {
            offset: 0,
            speed: -2.4,
        },
        layer4: {
            offset: 0.8,
            speed: -0.99,
        },
        layer5: {
            offset: 1.2,
            speed: 0.5,
        },
        layer6: {
            offset: 1.5,
            speed: 0,
        },
        layer7: {
            offset: 1,
            speed: 0.4,
            width: "50%",
            height: "360%",
        },
        layer8: {
            offset: -1,
            speed: -0.45,
        },
        layer9: {
            offset: 1.35,
            speed: 0.3,
        },
        layer10: {
            offset: 2.2,
            speed: 0.4,
        },
        layer11: {
            offset: 3.4,
            speed: 0.2,
        },
        
    },
    "4/3": {
        pages: 4.5,
        layer1: {
            offset: 0,
            speed: 1.4,
        },
        layer2: {
            offset: 0.2,
            speed: 2,
        },
        layer3: {
            offset: 0,
            speed: -2.4,
        },
        layer4: {
            offset: 0.8,
            speed: -0.99,
        },
        layer5: {
            offset: 1,
            speed: 0.5,
        },
        layer6: {
            offset: 1.3,
            speed: 0,
        },
        layer7: {
            offset: 1,
            speed: 0.9,
            width: "45%",
            height: "370%",
        },
        layer8: {
            offset: -1,
            speed: -0.45,
        },
        layer9: {
            offset: 1.35,
            speed: 0.3,
        },
        layer10: {
            offset: 2.2,
            speed: 0.4,
        },
        layer11: {
            offset: 3.4,
            speed: 0.2,
        },
        
    },
    "7/6": {
        pages: 4.5,
        layer1: {
            offset: 0,
            speed: 1.4,
        },
        layer2: {
            offset: 0.2,
            speed: 2,
        },
        layer3: {
            offset: 0,
            speed: -2.4,
        },
        layer4: {
            offset: 0.8,
            speed: -0.99,
        },
        layer5: {
            offset: 1,
            speed: 0.5,
        },
        layer6: {
            offset: 1,
            speed: 0,
        },
        layer7: {
            offset: 1,
            speed: 1.2,
            width: "35%",
            height: "370%",
        },
        layer8: {
            offset: -1,
            speed: -0.45,
        },
        layer9: {
            offset: 1.35,
            speed: 0.3,
        },
        layer10: {
            offset: 2.2,
            speed: 0.4,
        },
        layer11: {
            offset: 3.4,
            speed: 0.2,
        },
        
    },
    "1": {
        pages: 4.3,
        layer1: {
            offset: 0,
            speed: 1.4,
        },
        layer2: {
            offset: 0.2,
            speed: 2,
        },
        layer3: {
            offset: 0,
            speed: -2.4,
        },
        layer4: {
            offset: 0.92,
            speed: -0.99,
        },
        layer5: {
            offset: 1,
            speed: 0.5,
        },
        layer6: {
            offset: 0.99999,
            speed: 0,
        },
        layer7: {
            offset: 1,
            speed: 2,
            width: "35%",
            height: "370%",
        },
        layer8: {
            offset: -1,
            speed: -0.45,
        },
        layer9: {
            offset: 1.35,
            speed: 0.3,
        },
        layer10: {
            offset: 2.2,
            speed: 0.4,
        },
        layer11: {
            offset: 3.2,
            speed: 0.2,
        },
        
    },
    "3/4": {
        pages: 4.1,
        layer1: {
            offset: 0,
            speed: 1.4,
        },
        layer2: {
            offset: 0.3,
            speed: 2,
        },
        layer3: {
            offset: 0.2,
            speed: -2.4,
        },
        layer4: {
            offset: 2.55,
            speed: -0.5,
        },
        layer5: {
            offset: 1.4,
            speed: 0.8,
        },
        layer6: {
            offset: 1.5,
            speed: 0,
        },
        layer7: {
            offset: 1,
            speed: 2.8,
            width: "50%",
            height: "360%",
        },
        layer8: {
            offset: -1,
            speed: -0.43,
        },
        layer9: {
            offset: 2,
            speed: 0.7,
        },
        layer10: {
            offset: 2,
            speed: 0.4,
        },
        layer11: {
            offset: 3,
            speed: 0.2,
        },
        
    },
    "9/16": {
        pages: 4,
        layer1: {
            offset: 0,
            speed: 1.4,
        },
        layer2: {
            offset: 0.3,
            speed: 2,
        },
        layer3: {
            offset: 0.2,
            speed: -2.4,
        },
        layer4: {
            offset: 2.55,
            speed: -0.5,
        },
        layer5: {
            offset: 1.4,
            speed: 0.8,
        },
        layer6: {
            offset: 1.5,
            speed: 0,
        },
        layer7: {
            offset: 1,
            speed: 3,
            width: "40%",
            height: "360%",
        },
        layer8: {
            offset: -1,
            speed: -0.43,
            alignStart: "true"
        },
        layer9: {
            offset: 2,
            speed: 0.7,
        },
        layer10: {
            offset: 2,
            speed: 0.4,
        },
        layer11: {
            offset: 3,
            speed: 0.2,
        },
        
    },
    "9/19.5": {
        pages: 4.1,
        layer1: {
            offset: 0,
            speed: 1.4,
        },
        layer2: {
            offset: 0.3,
            speed: 2,
        },
        layer3: {
            offset: 0.2,
            speed: -2.4,
        },
        layer4: {
            offset: 2.55,
            speed: -0.5,
        },
        layer5: {
            offset: 1.4,
            speed: 0.8,
        },
        layer6: {
            offset: 1.5,
            speed: 0,
        },
        layer7: {
            offset: 1,
            speed: 3.2,
            width: "30%",
            height: "361%",
        },
        layer8: {
            offset: -1,
            speed: -0.43,
            alignStart: "true"
        },
        layer9: {
            offset: 2,
            speed: 0.7,
        },
        layer10: {
            offset: 2,
            speed: 2,
        },
        layer11: {
            offset: 3,
            speed: 0.2,
        },
        
    }
}
export { objectStage, AspectRatio, calculatingAR };